import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

const Offers = ({ data }) => {
	const off = data.strapiOffer;

	return (
		<Layout>
			<div className="">
				<GatsbySeo
					title="Offers"
					description="Now more reasons to signup your next cycling adventure with us."
					// languageAlternates={[
					// 	{
					// 		hrefLang: "en-IN",
					// 		href: "https://www.artofbicycletrips.in/terms-conditions",
					// 	},
					// 	{
					// 		hrefLang: "en",
					// 		href: "https://www.artofbicycletrips.com/terms-conditions",
					// 	},
					// ]}
				/>
				<div className="my-20">
					<h1 className="font-bold">Offers</h1>
					<p className="font-light text-xl text-primary max-w-3xl mb-14">
						More ways to rejoice and ride together!
					</p>
					{off.offer.map((node) => {
						return (
							<div className="my-20" key={node.id}>
								<ReactMarkdown
									className="prose prose-sm sm:prose"
									children={node.define}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query OffersQuery {
		strapiOffer {
			offer {
				define
			}
		}
	}
`;

export default Offers;
